import { App } from '@inertiajs/inertia-svelte'
import Honeybadger from 'honeybadger-js'
import '../stylesheets/application.scss'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import * as amplitudeSDK from '@amplitude/analytics-browser'
import { singularSdk, SingularConfig } from 'singular-sdk'

axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

const initAmplitude = (apiKey) => {
  const amplitudeOptions = {
    autoCapture: {
      attribution: true,
      pageViews: false,
      sessions: false,
      fileDownload: false,
      formInteractions: false,
      elementInteractions: false,
    },
    defaultTracking: false,
  }

  amplitudeSDK.init(apiKey, amplitudeOptions)
}

const initSingular = (sdkKey, sdkSecret) => {
  const config = new SingularConfig(
    sdkKey,
    sdkSecret,
    'com.changecollective.tenpercenthappier'
  )
  singularSdk.init(config)
}

document.addEventListener('DOMContentLoaded', async () => {
  const appElement = document.getElementById('app')
  const initialPage = JSON.parse(appElement.dataset.page)
  const { honeybadger, stripe, amplitude, environment, singular } =
    initialPage.props
  window.stripe = await loadStripe(stripe.publicKey, {
    betas: ['custom_checkout_beta_1'],
  })

  if (honeybadger.environment !== 'development') {
    Honeybadger.configure({ apiKey: honeybadger.apiKey, environment })
  }

  initAmplitude(amplitude.apiKey, environment)
  initSingular(singular.sdkKey, singular.sdkSecret)

  new App({
    target: appElement,
    props: {
      initialPage,
      resolveComponent: (name) => import(`../Pages/${name}.svelte`),
    },
  })
})
